import React, { useState, useEffect } from "react";
import AtomDek from "components/source/atoms/atom-dek";
import MoleculeInlinePositionedTooltip from "components/source/molecules/tooltip/molecule-inline-positioned-tooltip";
import { TOOLTIP } from "rtr-constants";
import { htmlElementPropType } from "components/propTypes";

const CustomerPromiseTooltip = ({ target }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  useEffect(() => {
    const isDismissed = localStorage.getItem("isCustomerPromiseTooltipDismissed") === "true" || false;
    if (!isDismissed) {
      setIsTooltipVisible(true);
    }
  }, []);

  const handleDismiss = () => {
    localStorage.setItem("isCustomerPromiseTooltipDismissed", "true");
    setIsTooltipVisible(false);
  };

  if (!isTooltipVisible) {
    return null;
  }

  const renderTooltipContent = () => {
    return (
      <div className="customer-promise-tooltip">
        <AtomDek
          text="Not loving an item?"
          mobileText="Not loving an item?"
          customClass="text-base font-semibold pb-2"
        />
        <AtomDek
          text="We'll make it right. Go to your Order History and select Get Help with an item."
          mobileText="We'll make it right. Go to your Order History and select Get Help with an item."
          customClass="text-sm font-thin pb-4"
        />
        <button
          className="rounded-[24px] h-[45px] w-full text-white bg-[#420022] text-sm font-bold box-border mx-auto disabled:bg-[#999999] disabled:pointer-events-none"
          onClick={handleDismiss}>
          Got it
        </button>
      </div>
    );
  };

  return (
    <MoleculeInlinePositionedTooltip
      className="absolute w-[336px] h-[189px] bg-white shadow-md flex flex-col justify-between p-6 md:h-auto"
      isClosedByOutsideClick={true}
      onClose={handleDismiss}
      placement={TOOLTIP.PLACEMENTS.TOP}
      target={target}>
      {renderTooltipContent()}
    </MoleculeInlinePositionedTooltip>
  );
};

CustomerPromiseTooltip.propTypes = {
  target: htmlElementPropType,
};

export default CustomerPromiseTooltip;
