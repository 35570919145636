import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { compose } from "redux";
import carouselTypes from "helpers/carousel-types";

import { childrenPropType } from "components/propTypes";
import ActionLogger from "action-logger";
import CarouselProgressionDots from "components/source/shared/carousels/carousel-progression-dots";
import PropTypesHelper from "helpers/PropTypesHelper";
import { onEnterPress } from "../../../../helpers/a11y-helper";
import { windowIsMedium, windowIsNarrow } from "helpers/device-helpers";
import { isSSR } from "helpers/client-server-helper";
import { connect } from "react-redux";
import { ScreenMinWidths } from "rtr-constants";
import { bindTouchGesturesWithAsyncHammer } from "../../../../helpers/touch-interaction-helper";
import { createScrollIntoViewPixelLogger } from "analytics/element-visibility-logger";

import SwipeableCarouselItem from "./swipeable-carousel-item";
import FlagsAndExperimentsActions from "actions/flags-and-experiments-actions";
import {
  flagsAndExperimentsPropType,
  withFlagsAndExperiments,
  flagsAndExperimentNames,
} from "../../hoc/with-flags-and-experiments";

export class SwipeableCarousel extends React.Component {
  static DEAD_END_SEARCH_RESULTS_OBJECT_TYPE = "dead_end_search_results";
  static DEAD_END_SEARCH_MODULE_TYPE = "dead_end_search_carousel";
  static propTypes = {
    fetchFeatureFlagConfiguration: PropTypes.func,
    flagsAndExperiments: flagsAndExperimentsPropType,
    additionalClassName: PropTypes.string,
    children: PropTypesHelper.requiredIf(childrenPropType, p => !p.displayCarouselProgressDots),
    // A neighborly carousel is meant to sit beside something else, and will
    // only ever extend to three items across
    neighborly: PropTypes.bool,
    viewAllCardLink: PropTypes.string,
    // A wider-width carousel to be extended to only
    // three items across
    wideWidthChildren: PropTypes.bool,
    // Carousels inside a grid layout on the no results page are responsive to page resizing
    carouselInGrid: PropTypes.bool,
    // specifies the number of images per page for a mobile view. default number is 2
    mobilePageSize: PropTypes.number,
    // specifies the number of images per page for a desktop view. default is defined in getPageSize
    pageSize: PropTypes.number,
    pixelData: PropTypes.object,
    locationType: PropTypes.string,
    logStylesInView: PropTypes.func,
    logSwipeToIndex: PropTypes.func,
    logClickArrow: PropTypes.func,
    logViewAllCard: PropTypes.func,
    carousel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    index: PropTypes.number,
    setIndex: PropTypes.number, // this prop is used to externally set the carousel index (via an outside Next button, for example)
    getCarouselIndex: PropTypes.func,
    displayCarouselProgressDots: PropTypes.bool,
    hideInactiveButtons: PropTypes.bool,
    hideSideButtons: PropTypes.bool,
    autoScrollForVariableChildren: PropTypes.bool,
    lightTheme: PropTypes.bool,
    deadEndCarousel: PropTypes.bool,
    deadEndSearchData: PropTypes.object,
    deadEndAPIAndCarouselData: PropTypes.object,
    scrollBySingleItem: PropTypes.bool, // scrolls items one at a time on desktop
    isMobile: PropTypes.bool,
    /**
     * Allows user to pan through carousel items gradually via touchdown, instead of moving one by one through icons.
     */
    useSmoothScroll: PropTypes.bool,
    isFullBleedCarousel: PropTypes.bool,
    isPeekCarousel: PropTypes.bool, // this carousel has the last item peeking halfway out, only works with full bleed carousels
    fullBleedCarouselButtonSelector: PropTypes.string,
  };

  static defaultProps = {
    setIndex: 0,
    hideSideButtons: false,
    additionalClassName: "",
    mobilePageSize: 2,
    logViewAllCard: () => {},
    hideInactiveButtons: false,
    autoScrollForVariableChildren: false,
    fourItemMaxWindowSize: 1200,
    threeItemMaxWindow: 1024,
    twoItemMaxWindow: 480,
    useSmoothScroll: false,
    flagsAndExperiments: {},
  };

  state = {
    canPageBack: false,
    /**
     * Distance traveled during a current set of pan actions that we ignore when processing new actions.
     * This is needed because Hammer.Pan records movement events even when they're not actionable.
     * I.e. a user scrolls left but there are no more tiles, then scrolls right, we need to ignore left scroll.
     */
    deltaXOffset: 0,
    index: 0,
    /**
     * Is the user currently in the middle of a series of pan actions?
     */
    panning: false,
    translation: 0,
    /**
     * The original translation from before a pan session was initiated.
     */
    translationOffset: 0,
    visibleProductCount: 0,
  };

  constructor(props) {
    super(props);
    this.elementRef = React.createRef(null);
    this.swipeableCarouselItemsRef = React.createRef();
    this.carouselItemRef = React.createRef();
  }

  componentDidMount() {
    const pageSize = this.getPageSize();

    this.setState({
      canPageForward: pageSize < this.props.children.length,
    });
    this.props.fetchFeatureFlagConfiguration();

    if (this.props.deadEndCarousel && this.state.visibleProductCount === 0) {
      this.triggerDeadEndCarouselVisibleProductsPixels();
    }

    this.initializeHammer();

    if (this.props.getCarouselIndex) {
      this.props.getCarouselIndex({
        currentIndex: Math.ceil(this.state.index / pageSize),
        maxItemsPerPage: pageSize,
        totalPages: Math.ceil(this.props.children.length / pageSize),
      });
    }

    if (Number.isInteger(this.props.setIndex) && this.props.setIndex > 0) {
      this.updatePage(this.props.setIndex);
    }

    const { scrollBySingleItem, isMobile, useSmoothScroll } = this.props;
    if (scrollBySingleItem || (isMobile && useSmoothScroll)) {
      //In this case, we calculate max width based on tile size and use it to determine if we can page forward.
      //This can only be done once the component has been mounted in the browser
      const canPageForward = this.getMaxTranslation() < 0;

      this.setState({ canPageForward });
    }

    window.addEventListener("resize", this.handleResize);
    this.scrollPixelLogger = this.createPixelLogger();
  }

  componentWillUnmount() {
    this.hammer?.destroy();
    window.removeEventListener("resize", this.handleResize);

    if (this.scrollPixelLogger) {
      this.scrollPixelLogger.disconnect();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.flagsAndExperiments?.[flagsAndExperimentNames.TUX_622_CAROUSELS_UNLOCK] && this.props.isMobile) {
      this.hammer?.destroy();
      this.swipeableCarouselItemsRef.current.style = "overflow:scroll;margin:0;-webkit-overflow-scrolling:touch;";
    }

    // ALAN CHEN - 10/21/19 - When the number of children are different than previously we should update the carousel buttons and indexes
    if (this.props.autoScrollForVariableChildren && prevProps.children.length !== this.props.children.length) {
      this.updatePage(this.getDisplacementWhenNumOfChildrenChange());
    }

    // NW [EXPLANATION] 2/8/21: an outside event (like a Next button click) has updated `setIndex` to change the page
    // we are calculating the displacement between the previous `setIndex` and the updated `setIndex`
    if (Number.isInteger(this.props.setIndex) && this.props.setIndex !== prevProps.setIndex) {
      this.updatePage(this.props.setIndex - prevProps.setIndex);
    }
  }

  initializeHammer() {
    const { useSmoothScroll, isMobile } = this.props;
    if (!isMobile || this.hammer) return;
    if (useSmoothScroll) {
      this.setupSmoothScroll();
    } else {
      this.setupSimpleScroll();
    }
  }

  /**
   * If the window is resized, the number of visible cards in a row may change.
   * Recalculate the translation, as the old one may push the carousel too far off screen.
   */
  handleResize = () => {
    //sbenedict 1/31/24
    //This is currently only supported by carousels that use the new panCarousel method to scroll.
    //In other words, either scrolling by single items or smooth scrolling on mobile.
    const { scrollBySingleItem, isMobile, useSmoothScroll, isFullBleedCarousel } = this.props;
    if (scrollBySingleItem || (isMobile && useSmoothScroll)) {
      this.panCarousel(0, this.state.translation, this.state.index);
    }

    // to recalculate button position on full bleed carousels when the window is resized
    if (isFullBleedCarousel) {
      this.forceUpdate();
    }
  };

  /**
   * Scroll behavior where a user swipe moves carousel by a given number of cards (single or multiple).
   */
  setupSimpleScroll() {
    bindTouchGesturesWithAsyncHammer(Hammer => {
      this.hammer = new Hammer(this.swipeableCarouselItemsRef.current);

      this.hammer.on("swipeleft", () => {
        this.updatePage(1);
      });

      this.hammer.on("swiperight", () => {
        this.updatePage(-1);
      });
    });
  }

  /**
   * Scroll behavior where a user pan moves smoothly through carousel based on distance traveled.
   */
  setupSmoothScroll() {
    bindTouchGesturesWithAsyncHammer(Hammer => {
      this.hammer = new Hammer.Manager(this.swipeableCarouselItemsRef.current, {
        recognizers: [[Hammer.Pan, { direction: Hammer.DIRECTION_HORIZONTAL }]],
      });

      this.hammer.on("panend", this.onPanEnd);

      this.hammer.on("panleft", ev => {
        this.handlePanLeft(ev);
      });

      this.hammer.on("panright", ev => {
        this.handlePanRight(ev);
      });
    });
  }

  handlePanLeft(ev) {
    //If the user can't page forward, ignore the pan action
    if (!this.state.canPageForward) {
      //Record the current distance moved, as we need to ignore it when calculating how much to update translation
      this.setState({ deltaXOffset: ev.deltaX });

      return;
    }

    this.handlePanAction(ev);
  }

  handlePanRight(ev) {
    //If the user can't page back, ignore the pan action
    if (!this.state.canPageBack) {
      //Record the current distance moved, as we need to ignore it when calculating how much to update translation
      this.setState({ deltaXOffset: ev.deltaX });

      return;
    }

    this.handlePanAction(ev);
  }

  /**
   *
   * @param {Object} ev - the HammerJS event
   * @param {number} ev.deltaX - the total relative distance traveled within a current pan session.
   * deltaX increases on pan right and decreases (into negatives) on pan left.
   * It represents total distance moved, and is reset at the end of a session (i.e. on a panend event).
   * @link https://hammerjs.github.io/api/#event-object
   */
  handlePanAction(ev) {
    //Prevent native browser event processing that causes page back/forward behavior to trigger
    ev.preventDefault();
    //Record the translation offset when the user starts an actionable pan session
    //We need to combine this value with the distance the user has moved within the session
    if (!this.state.panning) {
      this.setState({ panning: true, translationOffset: this.state.translation });
    }

    //Distance is the total distance moved within the session, less any distance that wasn't actionable
    const distance = ev.deltaX - this.state.deltaXOffset;

    this.panCarousel(distance, this.state.translationOffset);
  }

  onPanEnd = () => {
    this.setState({ deltaXOffset: 0, panning: false, translationOffset: 0 });
  };

  /**
   * The maximum translation is the width of all the cards, less 100 to keep the in-view row full.
   * Translation is a negative value (i.e. shifting items left off screen).
   */
  getMaxTranslation = () => {
    return Math.min(this.props.children.length * this.getTileWidth() * -1 + 100, 0);
  };

  /**
   * Calculate the index of the first item card based on the position of the carousel.
   * This is required when users can scroll gradually (on mobile) and not card-by-card.
   * @param {number} updatedTranslation - the new translation value
   */
  calculateIndex(updatedTranslation) {
    const index = Math.floor(Math.abs(updatedTranslation) / this.getTileWidth());

    //In rare cases (rapid panning) the updated translation will produce an index that exceeds the number of cards.
    return Math.min(index, this.props.children.length - 1);
  }

  /**
   * Allows us to determine the number of pixels of translation to use when sliding a single carousel card.
   * Also used to calculate the max translation of the carousel during swiping.
   * @returns element width as a percent of carousel width.
   */
  getTileWidth() {
    const { offsetWidth } = this.swipeableCarouselItemsRef.current;
    const { width } = this.swipeableCarouselItemsRef.current
      .querySelector(".swipeable-carousel__item")
      .getBoundingClientRect();

    return (width / offsetWidth) * 100;
  }

  /**
   * Slide the carousel horizontally (by updating the translation) by the given number of pixels.
   * @param {number} distance - distance to move, in pixels. Negative values pan forward, positive backward.
   * @param {number} offset - the translation prior to panning, in percent.
   * @param {number} updatedIndex - the new index of the first visible card, when panning by a specific card count. Optional.
   */
  panCarousel(distance, offset, updatedIndex) {
    const { offsetWidth } = this.swipeableCarouselItemsRef.current;
    const { index: currentIndex } = this.state;
    const { logSwipeToIndex } = this.props;

    //Translation is a percent, distance is in pixels
    //Combine the calculated translation with the offset (i.e. the position before the pan began)
    let translation = (distance / offsetWidth) * 100 + offset;
    //When panning by individual cards multiple times, the calculated translation can drift from the min/max.
    //This drift is on the order of less than a pixel, and won't be perceptible to the user.
    //If we're within a pixel of the max translation or 0, we can safely assume the user is at the end of a carousel.
    const canPageBack = translation < -1;
    const maxTranslation = this.getMaxTranslation();
    const canPageForward = translation > maxTranslation + 1;

    //When panning by a specific card count (desktop), we can specify that explicit index.
    //When using smooth scroll (mobile/tablet), we need to calculate the value.
    const newIndex = updatedIndex ?? this.calculateIndex(translation);

    if (currentIndex !== newIndex && typeof logSwipeToIndex === "function") {
      //We want to log any items in view, even if they are partially hidden
      const itemsInView = Math.round(100 / this.getTileWidth());

      logSwipeToIndex(newIndex, itemsInView);
    }

    if (!canPageBack) {
      translation = 0;
    }

    if (!canPageForward) {
      translation = maxTranslation;
    }

    this.setState({ canPageBack, canPageForward, index: newIndex, translation });
  }

  updatePage = displacement => {
    if (this.props.scrollBySingleItem) {
      this.scrollBySingleItem(displacement);
    } else {
      this.scrollByFullRow(displacement);
    }
  };

  /**
   * Scroll the carousel by X individual cards.
   * Positive values scroll forward, negative backwards.
   * @param {number} displacement - the number of cards to scroll.
   */
  scrollBySingleItem = displacement => {
    const { offsetWidth } = this.swipeableCarouselItemsRef.current;
    const panDistance = ((-displacement * this.getTileWidth()) / 100) * offsetWidth;

    const updatedIndex = this.state.index + displacement;

    this.panCarousel(panDistance, this.state.translation, updatedIndex);
  };

  /**
   * Scroll the carousel X number of full rows of cards.
   * Positive values scroll forward, negative backwards.
   * @param {number} displacement - the number of full rows to scroll
   */
  scrollByFullRow = displacement => {
    const { index } = this.state;
    const {
      deadEndCarousel,
      logStylesInView,
      logSwipeToIndex,
      children,
      carousel,
      getCarouselIndex,
      isPeekCarousel,
    } = this.props;
    let newIndex;
    const numVisibleItems = this.getPageSize();

    newIndex = displacement * numVisibleItems + index;
    newIndex = Math.max(newIndex, 0);
    let peekOffset = 0;
    let maxIndex;
    if (!isPeekCarousel) {
      maxIndex = children.length - 1;
      newIndex = Math.min(newIndex, maxIndex);
    } else {
      // peekCarousels have half a card peeking out as the last item. To account for this, we need to adjust the translation.
      // So, every time we scroll by a full page, we subtract half a card from the translation.
      // ex: if we scroll once, we subtract 0.5 cards. If we scroll twice, we subtract 1 card, and so on.
      const pageNumber = newIndex / numVisibleItems;
      const singleCardWidth = 100 / numVisibleItems;
      const halfCardWidth = singleCardWidth / 2;
      peekOffset = pageNumber * halfCardWidth;

      // To figure out the maxIndex, we need to account for ALL half cards we've subtracted from the translation.
      const totalPagesInCarousel = Math.ceil(children.length / numVisibleItems);
      maxIndex = children.length - 1 + totalPagesInCarousel / 2;
      newIndex = Math.min(newIndex, maxIndex);
    }
    const translation = (newIndex / this.getPageSize()) * -100 + peekOffset;

    if (deadEndCarousel && displacement === 1) {
      this.triggerDeadEndCarouselVisibleProductsPixels();
    } else if (typeof logStylesInView === "function" && index !== newIndex) {
      // NW [EXPLANATION] 4/19/19: We begin at index 0 because Data Science is asking for all styles viewed in the carousel, from the beginning to the current page, with order preserved.
      logStylesInView(this.stylesInView(0, newIndex + numVisibleItems), carousel, this.props.index, index, newIndex);
    }

    if (typeof logSwipeToIndex === "function") {
      logSwipeToIndex(newIndex);
    }

    this.setState({
      canPageBack: newIndex > 0,
      canPageForward: newIndex + this.getPageSize() <= maxIndex,
      index: newIndex,
      translation: translation,
    });

    if (getCarouselIndex) {
      getCarouselIndex({
        currentIndex: Math.ceil(newIndex / this.getPageSize()),
        totalPages: Math.ceil(children.length / this.getPageSize()),
      });
    }
  };

  getDisplacementWhenNumOfChildrenChange() {
    // ALAN CHEN - 10/21/19 - The index starts at 0 and is index of the first item in view.
    // When the first item is view is greater than the number of children then we need to scroll back one.
    return this.state.index > this.props.children.length - 1 ? -1 : 0;
  }

  getPageSize() {
    const { mobilePageSize, neighborly, pageSize, wideWidthChildren, isMobile, isPeekCarousel } = this.props;

    if (isMobile && mobilePageSize) {
      return mobilePageSize;
    }

    if (pageSize) {
      return pageSize;
    }

    // Only check this when running in a browser. IOW it breaks server side rendering.
    if (!isSSR()) {
      if (windowIsNarrow()) {
        // Mobile (default)
        return mobilePageSize;
      }

      if (windowIsMedium() || wideWidthChildren || neighborly) {
        // Tablet
        return 3;
      }
    }

    if (isPeekCarousel) {
      if (window.innerWidth > ScreenMinWidths.DESKTOP) {
        return 4.5;
      } else if (window.innerWidth > ScreenMinWidths.TABLET) {
        return 3.5;
      } else if (window.innerWidth > ScreenMinWidths.TABLET_SMALL) {
        return 2.5;
      } else {
        return 1.5;
      }
    }
    // Desktop
    return 5;
  }

  countOnPage() {
    const { defaultProps } = this.constructor;
    if (window.innerWidth > defaultProps.fourItemMaxWindowSize) {
      return 5;
    }
    if (window.innerWidth > defaultProps.threeItemMaxWindow) {
      return 4;
    }
    if (window.innerWidth > defaultProps.twoItemMaxWindow) {
      return 3;
    }
    return 2;
  }

  stylesInCarousel() {
    if (!this.props.children?.length) return;

    return this.props.children
      .filter(c => c)
      .map(child => {
        const values = Array.isArray(child) ? child[0] : child;
        if (values.props?.product) {
          return values.props.product.id;
        }
      })
      .filter(s => s);
  }

  stylesInView(startIndex, endIndex) {
    return this.stylesInCarousel().slice(startIndex, endIndex);
  }

  triggerDeadEndCarouselVisibleProductsPixels() {
    const { deadEndAPIAndCarouselData, deadEndSearchData, carousel } = this.props;
    const prodCount = this.state.visibleProductCount + this.countOnPage();
    const prodArray = carousel.products.slice(0, prodCount);
    this.setState({ visibleProductCount: prodCount });
    const apiAndCarouselData = deadEndAPIAndCarouselData;
    const dead_end_module_type = this.constructor.DEAD_END_SEARCH_MODULE_TYPE;
    const deadEndPixelData = {
      module_type: dead_end_module_type,
      ...deadEndSearchData,
      ...apiAndCarouselData,
      visible_products: JSON.stringify(prodArray.map(product => product.id)),
    };
    ActionLogger.logAction(
      Object.assign(
        {
          object_type: this.constructor.DEAD_END_SEARCH_RESULTS_OBJECT_TYPE,
          action_type: "visible_products",
        },
        deadEndPixelData
      )
    );
  }

  createPixelLogger() {
    // If there is pixel data associated with the carousel, log it on scroll
    const { pixelData } = this.props;

    if (!pixelData || !Object.keys(pixelData).length) {
      return null;
    }

    const callbackWithExperimentLogging = () => {
      if (typeof pixelData.callback === "function") {
        pixelData.callback();
      }
    };

    return createScrollIntoViewPixelLogger(
      this.elementRef,
      pixelData.action || "carousel",
      pixelData.options,
      callbackWithExperimentLogging
    );
  }

  triggerDeadEndClickIntoPDP(child) {
    const { carousel, deadEndSearchData, deadEndAPIAndCarouselData } = this.props;
    const { props: { product: product } = {} } = child || {};
    const { props: { product: { id: prodId } = {} } = {} } = child || {};
    const dead_end_module_type = this.constructor.DEAD_END_SEARCH_MODULE_TYPE;
    const productIndex = carousel.products.indexOf(product);
    const deadEndPixelData = {
      module_type: dead_end_module_type,
      ...deadEndSearchData,
      ...deadEndAPIAndCarouselData,
      style_name: prodId,
      nth_style: productIndex,
    };
    ActionLogger.inferAction(
      Object.assign(
        {
          object_type: this.constructor.DEAD_END_SEARCH_RESULTS_OBJECT_TYPE,
          action_type: "click_into_pdp",
        },
        deadEndPixelData
      )
    );
  }

  logItemClick = child => {
    // If there is pixel data associated with the carousel, log any clicks on products as well
    const { pixelData: { carousel_type: carouselType } = {} } = this.props;
    const { props: { product: { id: productId } = {} } = {} } = child || {};

    if (this.props.deadEndCarousel) {
      this.triggerDeadEndClickIntoPDP(child);
    }
    ActionLogger.inferAction({
      carousel_type: carouselType,
      sku: productId,
      object_type: "PDP",
      action: "click_from_carousel",
    });
  };

  logViewAllCardClick = () => {
    this.props.logViewAllCard(this.props.carousel, this.props.index, "product_card");
  };

  renderViewAllCard() {
    if (!this.props.viewAllCardLink) {
      return;
    }

    return (
      <div className="swipeable-carousel__item">
        <a className="swipeable-carousel__card" onClick={this.logViewAllCardClick} href={this.props.viewAllCardLink}>
          <div className="swipeable-carousels__card--link">View All</div>
        </a>
      </div>
    );
  }

  handleClickDirectionalButton = (direction, event) => {
    if (event) {
      event.preventDefault();
    }
    this.updatePage(direction);

    if (this.props.logClickArrow && this.props.carousel) {
      this.props.logClickArrow(direction, this.props.carousel, this.state.index);
    }
  };

  carouselTypeClass = () => {
    const { locationType } = this.props;
    switch (locationType) {
      case carouselTypes.CLEARANCE:
        return "clearance_items";
      case carouselTypes.RECOMMENDATION:
        return "recommendation_items";
      case carouselTypes.COLLECTION:
        return "collection_items";
      default:
        return "similar_items";
    }
  };

  // This seems redundant but it insures consistent event tracking in HEAP
  scrollTypeClass = () => {
    const { locationType } = this.props;
    switch (locationType) {
      case carouselTypes.CLEARANCE:
        return "clearance_scroll";
      case carouselTypes.RECOMMENDATION:
        return "recommendation_scroll";
      case carouselTypes.COLLECTION:
        return "collection_scroll";
      default:
        return "similar_scroll";
    }
  };

  renderDirectionalButton(directionalClass, disabled, direction, text) {
    if (this.props.hideSideButtons) {
      return null;
    }

    const hideButtonClass = !this.state.canPageBack && !this.state.canPageForward ? " button-hide" : "";

    if (this.props.isFullBleedCarousel) {
      if (!this.state.canPageBack && !this.state.canPageForward) {
        return null;
      }
      // try to calculate the button position based on the height of the first product card image
      // otherwise, take our best guess at it by estimating the height of the product details
      const top = this.carouselItemRef?.current?.querySelector(this.props.fullBleedCarouselButtonSelector)
        ?.clientHeight;

      const halfButtonHeight = 18;
      const estimatedProductDetailsHeight = 16;

      return (
        <button
          disabled={disabled}
          className={`full-bleed-button full-bleed-button--${directionalClass} ${this.scrollTypeClass()}`}
          data-test-id={`swipeable-carousel-${directionalClass}-button`}
          onClick={e => this.handleClickDirectionalButton(direction, e)}
          style={{
            top: top
              ? `${Math.round(top / 2) - halfButtonHeight}px`
              : `calc(50% - ${halfButtonHeight + estimatedProductDetailsHeight}px})`,
          }}>
          <span className={`full-bleed-button-icon full-bleed-button-icon--${directionalClass}`} />
        </button>
      );
    }
    return (
      <div
        className={`swipeable-carousel__button-wrapper swipeable-carousel__button-wrapper--${directionalClass}${hideButtonClass}`}>
        <button
          className={`swipeable-carousel__button swipeable-carousel__button--${directionalClass}`}
          data-test-id={`swipeable-carousel-${directionalClass}-button`}
          disabled={disabled}
          onClick={e => this.handleClickDirectionalButton(direction, e)}>
          {text}
        </button>
      </div>
    );
  }

  renderBackButton() {
    if (this.props.hideInactiveButtons && !this.state.canPageBack) {
      return null;
    }
    return this.renderDirectionalButton("back", !this.state.canPageBack, -1, "Back");
  }

  renderForwardButton() {
    if (this.props.hideInactiveButtons && !this.state.canPageForward) {
      return null;
    }
    return this.renderDirectionalButton("forward", !this.state.canPageForward, 1, "Forward");
  }

  renderCarouselProgressionDots() {
    if (!this.props.displayCarouselProgressDots) {
      return;
    }

    return (
      <CarouselProgressionDots
        totalChildren={this.props.children.length}
        pageSize={this.getPageSize()}
        currentIndex={this.state.index}
        updatePage={this.updatePage}
      />
    );
  }

  render() {
    const {
      children = [],
      isFullBleedCarousel,
      isPeekCarousel,
      logSwipeToIndex,
      isMobile,
      flagsAndExperiments,
    } = this.props;
    // If the carousel cannot be paged forward or backward then
    // there is no reason to show the carousel buttons
    const transformation = "translate3d(" + this.state.translation + "%, 0, 0)";
    const style = {
      MozTransform: transformation,
      msTransform: transformation,
      transform: transformation,
      WebkitTransform: transformation,
    };

    const carouselClassName = classNames("swipeable-carousel", {
      "swipeable-carousel--in-grid": this.props.carouselInGrid,
      "swipeable-carousel--neighborly": this.props.neighborly,
      "swipeable-carousel--full-page": this.props.pageSize === 1,
      "swipeable-carousel--light-theme": this.props.lightTheme,
      [this.props.additionalClassName]: this.props.additionalClassName,
    });

    const viewportClassName = classNames("swipeable-carousel__viewport", {
      "swipeable-carousel__viewport--full-bleed-carousel": isFullBleedCarousel,
    });

    const buttonClassName = classNames("swipeable-carousel__item", {
      "swipeable-carousel__item--full-bleed-carousel": isFullBleedCarousel,
      "swipeable-carousel__item--peek-carousel": isPeekCarousel,
      [this.carouselTypeClass()]: this.props.locationType,
    });

    return (
      <div
        className={carouselClassName}
        ref={this.elementRef}
        data-item-count={children.length}
        data-test-id="swipeable-carousel">
        {!isFullBleedCarousel && this.renderBackButton()}
        <div className={viewportClassName}>
          <div
            className="swipeable-carousel__items"
            data-test-id="carousel-items"
            ref={this.swipeableCarouselItemsRef}
            style={style}>
            {children.map((child, i) =>
              !isMobile || (!flagsAndExperiments?.[flagsAndExperimentNames.TUX_622_CAROUSELS_UNLOCK] && isMobile) ? (
                <div
                  role="button"
                  tabIndex={0}
                  onKeyPress={onEnterPress(() => this.logItemClick(child))}
                  className={buttonClassName}
                  onClick={() => this.logItemClick(child)}
                  key={i}
                  ref={i === 0 ? this.carouselItemRef : null}>
                  {child}
                </div>
              ) : (
                <SwipeableCarouselItem
                  role="button"
                  tabIndex={0}
                  onKeyPress={onEnterPress(() => this.logItemClick(child))}
                  className={buttonClassName}
                  onClick={() => this.logItemClick(child)}
                  key={i}
                  index={i}
                  ref={i === 0 ? this.carouselItemRef : null}
                  isMobile={this.props.isMobile}
                  root={this.swipeableCarouselItemsRef}
                  logMethod={logSwipeToIndex}>
                  {child}
                </SwipeableCarouselItem>
              )
            )}
            {this.renderViewAllCard()}
          </div>
        </div>
        {isFullBleedCarousel && this.renderBackButton()}
        {this.renderForwardButton()}
        {this.renderCarouselProgressionDots()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isMobile: state.browser?.isMobileViewport,
});

const mapDispatchToProps = dispatch => {
  return {
    fetchFeatureFlagConfiguration: () =>
      dispatch(FlagsAndExperimentsActions.fetchFlagOrExperiment(flagsAndExperimentNames.TUX_622_CAROUSELS_UNLOCK)),
  };
};

export default compose(
  withFlagsAndExperiments(flagsAndExperimentNames.TUX_622_CAROUSELS_UNLOCK),
  connect(mapStateToProps, mapDispatchToProps)
)(SwipeableCarousel);
