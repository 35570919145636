import PropTypes from "prop-types";

export default function Signal({ itemLeft, className }) {
  if (!Number.isInteger(itemLeft)) {
    return;
  }

  return (
    <p className={`text-md font-sans text-contentNegative ${className ? className : ""}`} data-test-id="urgency-signal">
      {`Only ${itemLeft} left!`}
    </p>
  );
}

Signal.propTypes = {
  itemLeft: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
};
