import Signal from "components/source/universal_pdp/urgency-signal/signal";
import { flagsAndExperimentNames, membershipLensForAvailability } from "rtr-constants";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  getUserDataLensForAvailability,
  isClassicLens,
  isRtrUpdateLens,
  isUnlimitedLens,
} from "helpers/membership-helpers";
import { useEffect } from "react";
import HeapHelpers from "helpers/heap-helpers";

const LEGACY_PDP_THRESHOLD_FOR_URGENCY = 3;

export function getAvailability(sku, userData, isClearance, isBuyNow) {
  if (!sku) return;
  if (isBuyNow) {
    return sku["unlimitedAvailabilities"]?.[0]?.count;
  } else if (isClearance) {
    return sku["clearanceAvailabilities"]?.[0]?.count;
  } else {
    let rentalAvailabilityKey = getUserDataLensForAvailability(userData, membershipLensForAvailability.unlimited);

    // had to do this weird hack cause the function seems to be returning wrong casing of "rtrUpdate"
    // and I dont want to update the function because its being used product-actions
    if (membershipLensForAvailability.RTRUpdate === rentalAvailabilityKey) {
      rentalAvailabilityKey = "rtrUpdate";
    }

    return sku?.[`${rentalAvailabilityKey}Availabilities`]?.[0]?.count;
  }
}

export function calculateEligiblityToShowUrgencySignalOnLegacyPdp(
  skuAvailibilityForAllIntent,
  selectedSize,
  showUrgencySignal,
  userData,
  isClearance,
  isBuyNow,
  thresholdForUrgency = LEGACY_PDP_THRESHOLD_FOR_URGENCY
) {
  if (
    !(
      Array.isArray(skuAvailibilityForAllIntent) &&
      skuAvailibilityForAllIntent.length > 0 &&
      !!selectedSize &&
      showUrgencySignal
    )
  ) {
    return { isEligible: false, availabilityOfSelectedSku: 0 };
  }

  // have to do this weird check because membership, buynow, clearance dropdown send size as regional size but reserve
  // sends sku value
  const selectedSizeObject = skuAvailibilityForAllIntent.find(o => o?.size === selectedSize || o?.id === selectedSize);
  const availabilityOfSelectedSku = getAvailability(selectedSizeObject, userData, isClearance, isBuyNow);

  const isEligible =
    Number.isInteger(availabilityOfSelectedSku) &&
    Number.isInteger(thresholdForUrgency) &&
    availabilityOfSelectedSku > 0 &&
    availabilityOfSelectedSku <= thresholdForUrgency;

  return { isEligible, availabilityOfSelectedSku };
}

export default function UrgencySignalWrapper({
  thresholdForUrgency = LEGACY_PDP_THRESHOLD_FOR_URGENCY,
  selectedSize,
  className,
}) {
  const userData = useSelector(state => state.userData);
  const isBuyNow = useSelector(state => state.isBuyNow);
  const isClearance = useSelector(state => state.product?.price?.isClearance);
  const skuAvailibilityForAllIntent = useSelector(state => state.product?.skus);
  const showUrgencySignal = useSelector(
    state => state.flagsAndExperiments?.[flagsAndExperimentNames.RSV_1421_URGENCY_SIGNAL]
  );

  const { isEligible, availabilityOfSelectedSku } = calculateEligiblityToShowUrgencySignalOnLegacyPdp(
    skuAvailibilityForAllIntent,
    selectedSize,
    showUrgencySignal,
    userData,
    isClearance,
    isBuyNow
  );

  useEffect(() => {
    if (isEligible) {
      HeapHelpers.fireHeapEvent("urgency_signal_shown", {
        authStatus: userData?.authState,
        selectedSize,
        availabilityOfSelectedSku,
        isBuyNow,
        isClearance,
        unlimitedLens: isUnlimitedLens(userData),
        rtrUpdateLens: isRtrUpdateLens(userData),
        classicLens: isClassicLens(userData),
        skuAvailibilityForAllIntent,
        sku: skuAvailibilityForAllIntent?.[0]?.id,
      });
    }
  }, [selectedSize, isBuyNow, isClearance, userData]);

  if (!isEligible) {
    return;
  }

  return (
    <Signal itemLeft={availabilityOfSelectedSku} thresholdForUrgency={thresholdForUrgency} className={className} />
  );
}

UrgencySignalWrapper.propTypes = {
  thresholdForUrgency: PropTypes.number,
  className: PropTypes.string.isRequired,
  selectedSize: PropTypes.string.isRequired,
};
