import ActionLogger from "action-logger";

/**
 * Built as a replacement for ScrollPixelLogger, which relied on legacy React methods and was only used in Class components.
 * This requires that the IntersectionObserver instance be explicitly disconnected at the end of the component lifecyle.
 * If we do something similar in Function components (say, for Heap events), that should likely be placed into a custom React hook that also handles disconnect.
 */
export const createScrollIntoViewCallback = (elementRef, onScrollIntoView) => {
  if (!elementRef || !elementRef.current) {
    return null;
  }

  const observer = new IntersectionObserver(([entry], observer) => {
    if (entry.isIntersecting) {
      onScrollIntoView();

      //Stop watching the element once we've fired the callback once
      observer.unobserve(entry.target);
    }
  });

  observer.observe(elementRef.current);

  return observer;
};

export const createScrollIntoViewPixelLogger = (elementRef, action, options = {}, additionalCallback = () => {}) => {
  const logData = {
    object_type: "scroll_read",
    action: action,
    context: "scroll", // this extra bit of data is to help report on scroll logs
    ...options,
  };

  const onScrollIntoView = () => {
    ActionLogger.logAction(logData);
    additionalCallback();
  };

  return createScrollIntoViewCallback(elementRef, onScrollIntoView);
};
