import { useState, useEffect } from "react";
import NavItems from "./nav-items";
import RtrLogo from "images/sprites/reb/svg/rtr-logo-tw.svg";
import RtrLogoMobile from "images/sprites/reb/svg/mobile-rtr-logo-tw.svg";
import Hamburger from "images/sprites/reb/svg/hamburger-tw.svg";
import SearchIcon from "images/sprites/reb/svg/search-icon.svg";
import CloseIcon from "images/sprites/reb/svg/close-tw.svg";
import BagIcon from "images/sprites/reb/svg/bag-icon-tw.svg";
import NavLinksResponse from "./mockNavItemsResponse";
import UserMenu from "../user-menu";

const Navigation = () => {
  const [scrollDepth, setScrollDepth] = useState(0);
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
  const navHeight = 64; // I'm not sure if it's a post CSS thing but for some reason this value won't work with the tailwind classNames

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setScrollDepth(scrollTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navLinks = NavLinksResponse;

  return (
    // NOTE: tailwind nav class is for overrides on older components
    <div
      className={`tailwind-nav bg-white border-b border-darkGray border-opacity-50 realtive overflow-visible `}
      data-test-id="global-nav">
      <div
        className={`pl-3 pr-3 md:pl-32 md:pr-32 h-10 md:h-16 grid grid-cols-3 items-center fixed w-full z-10 top-0 bg-white transition-all ${
          scrollDepth > navHeight ? "border-b border-darkGray border-opacity-50" : ""
        }`}>
        <div className="col-span-1">
          <div className="search-bar--desktop flex items-center">
            <div className="pr-6">
              {/* HAMBURGER: only displayed on mobile */}
              <button
                className="md:hidden"
                onClick={() => setIsHamburgerOpen(!isHamburgerOpen)}
                data-test-id="hamburger-btn">
                <span className={isHamburgerOpen ? "hidden" : "block"}>
                  <Hamburger />
                </span>
                <span className={isHamburgerOpen ? "block" : "hidden"}>
                  <CloseIcon />
                </span>
              </button>
              {/* To be replaced with new search bar component */}
              <SearchIcon className="hidden md:block" />
            </div>

            {/* This will be replaced with a CMS controlled component */}
            <a href="/" className="hidden md:block">
              How it works
            </a>
          </div>
        </div>
        <div className="flex justify-center">
          <span className="hidden md:block">
            <RtrLogo />
          </span>
          <span>
            <RtrLogoMobile className="block md:hidden" />
          </span>
        </div>
        <div className="flex justify-end">
          {/* NOTE: user menu will need to be modified to fir the new experience */}
          <div className="hidden md:block">
            <UserMenu
              isWideWidth={true}
              toggleMobileNavAccountMenu={() => null}
              isMobileNavExpanded={false}
              isMobileAccountMenuExpanded={false}
              toggleMobileNav={() => null}
            />
          </div>
          <BagIcon className="block md:hidden" />
        </div>
      </div>

      <div className={`md:pl-32 md:pr-32 overflow-x-scroll md:overflow-x-hidden mt-10 md:mt-16`}>
        {/* The same items are used in different places with different styles - hence passing tailwind classes as a */}
        <NavItems
          items={navLinks}
          parentClass="flex md:justify-between w-full"
          childClass="text-sm md:text-md p-3 md:pt-0 md:pb-4 md:pl-0 md:pr-0 text-center nowrap"
        />
      </div>

      <div
        className={`bg-white min-h-screen duration-200 fixed z-10 top-10 w-screen md:hidden  ${
          isHamburgerOpen ? "left-0" : "-left-[110%]"
        }`}
        data-test-id="hamburger-menu">
        <NavItems
          items={navLinks}
          parentClass="grid grid-cols-2 m-6 [&>li:nth-child(1)]:border-t [&>li:nth-child(2)]:border-t [&>li:nth-child(odd)]:border-r"
          childClass="text-sm md:text-md p-3 md:pt-0 md:pb-4 md:pl-0 md:pr-0 text-center nowrap border-b border-darkGray border-opacity-20"
        />
      </div>
    </div>
  );
};

export default Navigation;
