import { metrics } from "@opentelemetry/api";

let initialized = false;
/** @type {import("@opentelemetry/api").Counter} */
let casClientResultMetric;
/** @type {import("@opentelemetry/api").Counter} */
let casClientLogoutMetric;

export const initMetrics = () => {
  if (initialized) {
    return;
  }

  // Create a Meter for the application
  const meter = metrics.getMeter("storefront");

  // Create a custom counter metric for CAS results
  casClientResultMetric = meter.createCounter("rtr.cas_client_result", {
    description: "Counts the number of CAS callbacks",
  });

  casClientLogoutMetric = meter.createCounter("rtr.cas_client_logout", {
    description: "Counts the number of CAS Logouts",
  });

  // add any other metrics here

  initialized = true;
};

/**
 * Record a CAS logout This emits a metric which is monitored in our O11y dashboarding
 */
export const recordCasLogout = () => {
  initMetrics();
  casClientLogoutMetric.add(1, {
    clientId: process.env.CAS_SF_CLIENT_ID,
    is_error: false,
    sf_operation: "/logout",
  });
};

/**
 * Record a CAS success This emits a metric which is monitored in our O11y dashboarding
 */
export const recordCasSuccess = () => {
  initMetrics();
  casClientResultMetric.add(1, {
    clientId: process.env.CAS_SF_CLIENT_ID,
    is_error: false,
    sf_operation: "/login",
  });
};

// Function to record a CAS failure with a specific reason
// in TS future, reason should be a string union
/**
 * Record a CAS failure with a specific reason. This emits a metric which is monitored in our O11y dashboarding
 * @param {string} reason The reason the failure occured (as an underscore seperated string)
 */
export const recordCasFailure = reason => {
  initMetrics();
  casClientResultMetric.add(1, {
    reason,
    clientId: process.env.CAS_SF_CLIENT_ID,
    is_error: true,
    sf_operation: "/login",
  });
};
