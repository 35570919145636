import { authFormConstants } from "rtr-constants/auth";
import { isSSR } from "helpers/client-server-helper";
import { EVENTS_CUSTOM_DOM, QUERY_PARAMS, COOKIES } from "rtr-constants";
import { getLocationHref, getQueryParam, mergeQueryParams, navigateTo } from "helpers/location-helpers";
import tryCatchLog from "./try-catch";
import { LocalStorage } from "../site/localStorage";
import ConsumerAuthServiceClient from "clients/ConsumerAuthServiceClient";
import { cleanSession } from "helpers/session-helpers";
import Cookies from "universal-cookie";
import Routes from "routes";
import { setCastleRequestTokenCookie } from "helpers/castle-helper";

const { PageNames, PageTypes, Views } = authFormConstants;

const REFRESH_TOKEN_EXCLUDED_API_ROUTES = [
  "/account/login",
  "/account/register",
  "/api/account/login",
  "/api/account/register",
  "/api/auth/rtr-dac/initiation",
  "/api/auth/rtr-dac/resendChallenge",
  "/api/auth/rtr-dac/verifyChallenge",
  /\/api\/checkout\/limitedAccount\/[0-9a-fA-F]{24}\/checkout/,
];

const REFRESH_TOKEN_EXCLUDED_PAGE_ROUTES = [Routes.AUTH.SIGNIN];

export const authLocalStorageClient = new LocalStorage("auth");

export const getPostAuthenticationDestination = authModal => {
  const destination = authModal?.destination;

  if (destination) return destination;

  const destinationQueryParam = getQueryParam("require_auth") || getQueryParam("destination");

  if (!destinationQueryParam) {
    return "";
  }

  return decodeURIComponent(destinationQueryParam);
};

export const isLogin = authModal => authModal?.view === Views.login;

export const isRegistration = authModal => authModal?.view === Views.registration;

/**
 * Helper to determine if we are on standalone authentication pages (/account/login or /account/register)
 * @param {String} pageName page name set server-side provided by Redux state (state.pageName)
 * @param {String} pageType page type set server-side provided by Redux state (state.pageType)
 * @returns {Boolean}
 */
export const isStandaloneAuthenticationPage = (pageName, pageType) =>
  pageType === PageTypes.ACCOUNT && [PageNames.LOGIN, PageNames.REGISTER].includes(pageName);

export function onGoogleOneTapInit(clientId, createGoogleOneTapAuthenticationAttempt) {
  function callback() {
    try {
      google.accounts.id.initialize({
        client_id: clientId,
        callback: createGoogleOneTapAuthenticationAttempt,
        cancel_on_tap_outside: false,
      });
      window.google.accounts.id.prompt();
    } catch {
      // Do not log google one-tap errors as they are very, very noisy and in-actionable
    } finally {
      window.removeEventListener(EVENTS_CUSTOM_DOM.GOOGLE_ONE_TAP_LOAD, callback);
    }
  }

  if (
    !isSSR() &&
    typeof window.google?.accounts?.id?.initialize === "function" &&
    typeof window.google?.accounts?.id?.prompt === "function"
  ) {
    callback();
  } else {
    window.addEventListener(EVENTS_CUSTOM_DOM.GOOGLE_ONE_TAP_LOAD, callback);
  }
}

export function onGoogleOneTapClose() {
  function callback() {
    try {
      window.google.accounts.id.cancel();
    } catch {
      // Do not log google one-tap errors as they are very, very noisy and in-actionable
    } finally {
      window.removeEventListener(EVENTS_CUSTOM_DOM.GOOGLE_ONE_TAP_LOAD, callback);
    }
  }

  if (!isSSR() && typeof window?.google?.accounts?.id?.cancel === "function") {
    callback();
  } else {
    window.addEventListener(EVENTS_CUSTOM_DOM.GOOGLE_ONE_TAP_LOAD, callback);
  }
}

export function initializeGoogleOneTap(clientId, createGoogleOneTapAuthenticationAttempt) {
  // The 'google' object is ready for use at this point.
  try {
    google.accounts.id.initialize({
      client_id: clientId,
      callback: createGoogleOneTapAuthenticationAttempt,
      cancel_on_tap_outside: false,
    });
    window.dispatchEvent(new CustomEvent(EVENTS_CUSTOM_DOM.GOOGLE_ONE_TAP_LOAD));
  } catch (e) {
    // Do not log google one-tap errors as they are very, very noisy and in-actionable
  }
}

/**
 * Function to handle client-side navigation after a successful authentication.
 * @param {string} destinationPath A destination path to navigate to after authentication.
 * @param {URLSearchParams} postAuthenticationQueryParams Search params to add to the url (i.e. login=true)
 */
export function handlePostAuthenticationClientSideNavigation(destinationPath, postAuthenticationQueryParams) {
  let newUrl = "/";
  let updatedQueryParams;

  if (destinationPath) {
    const newDestination = new URL(decodeURIComponent(destinationPath), window.location.origin);

    updatedQueryParams = mergeQueryParams(
      new URLSearchParams(newDestination.searchParams),
      postAuthenticationQueryParams
    );

    newUrl = newDestination.pathname + "?" + updatedQueryParams.toString() + newDestination.hash;
  } else {
    // We are going to refresh the page and want to maintain any existing query params (i.e. if we are on Grid or PDP)
    const url = new URL(getLocationHref(), window.location.origin);
    const urlParams = new URLSearchParams(url.searchParams);
    urlParams.delete(QUERY_PARAMS.LOGOUT);
    updatedQueryParams = mergeQueryParams(urlParams, postAuthenticationQueryParams);
    newUrl = url.pathname + "?" + updatedQueryParams.toString() + url.hash;
  }

  tryCatchLog(() => navigateTo(newUrl));
}

/**
 * Function to get post authentication query param to append to the url
 * @param {Object} authModal Redux 'authModal' state
 * @returns {URLSearchParams} Search params to add to the url (i.e. login=true)
 */
export function getPostAuthenticationQueryParam(authModal) {
  const additionalParams = new URLSearchParams();

  if (isLogin(authModal)) {
    additionalParams.append(QUERY_PARAMS.LOGIN, "true");
  } else if (isRegistration(authModal)) {
    additionalParams.append(QUERY_PARAMS.REGISTRATION, "new");
  }

  return additionalParams;
}

/**
 * Function to register an event listener, specifically for the storage event, that will look to see if:
 * 1. the event pertains to the user id hash (auth>uih)
 * 2. the user id hash value has changed
 * 3. the document is currently hidden (i.e a background tab)
 * If these conditions are met, we will then refresh the page so that background tabs are not operating with a stale
 * session. This allows us to show members an up-to-date experience if they have multiple browsing tabs open, and they've
 * either signed in, signed out or registered with a new account.
 * @see {https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event}
 * @see {https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilityState}
 */
export function registerStorageListenerForAuthChanges() {
  window.addEventListener("storage", event => {
    if (event.storageArea !== localStorage) return;
    if (
      event.key === authLocalStorageClient.key("uih") &&
      event.newValue !== event.oldValue &&
      document.visibilityState === "hidden"
    ) {
      window.location.reload();
    }
  });
}

export async function refreshAccessToken() {
  try {
    // The refresh has a dependency on having an up-to-date Castle request token
    await setCastleRequestTokenCookie();
    await ConsumerAuthServiceClient.getInstance().refreshTokenClientSide();
    return Promise.resolve(true);
  } catch {
    return Promise.resolve(false);
  }
}

export async function handle401Response(response, requestUrl, getRetryRequest) {
  const cookies = new Cookies();
  const isCasV1Enabled = cookies.get(COOKIES.CAS_V1_ENABLED) === "true";
  const isExcludedApiRoute = REFRESH_TOKEN_EXCLUDED_API_ROUTES.some(route =>
    new URL(requestUrl, window.location.origin).pathname.match(route)
  );
  // No need to try to refresh tokens on pages where the refresh will happen automatically or the user is intended to
  // get prompted to sign in.
  const isExcludedPageRoute = REFRESH_TOKEN_EXCLUDED_PAGE_ROUTES.some(route =>
    new RegExp(route).test(window.location.pathname)
  );
  /* we should not attempt a token refresh for users that are not logged in.
  // authState should be the source of truth for this, but there is a case where the request to `/api/auth/~me~`
  // has not returned yet when a 401 is received. can be revisited if `/api/auth/~me~` has low latency */
  const isLoggedIn = cookies.get(COOKIES.RTR_LOGGED_IN) === "true";

  if (isCasV1Enabled && !isExcludedApiRoute && !isExcludedPageRoute && isLoggedIn) {
    const accessTokenRefreshed = await refreshAccessToken();

    if (accessTokenRefreshed) {
      // the user's access token has been refreshed, and we can retry the initial request
      return await retryIntialRequest(getRetryRequest);
    } else {
      // refreshing the user's access token has failed
      // log the user out (current page will refresh)
      await cleanSession();
    }
  }

  return response;
}

async function retryIntialRequest(getRetryRequest) {
  const retryResponse = await getRetryRequest();

  if (retryResponse.status === 401) {
    // the initial request is still unauthenticated - log the user out (current page will refresh)
    await cleanSession();
  }
  return retryResponse;
}

export function getIsOneTrustDisableButtonOverrideEnabled(
  isRegistration,
  isButtonDisabledOverrideKillSwitchFlagEnabled
) {
  if (
    typeof window === "undefined" ||
    typeof window.navigator === "undefined" ||
    typeof window.navigator.userAgent === "undefined"
  ) {
    return false;
  }
  if (isRegistration) {
    return false;
  }
  if (isButtonDisabledOverrideKillSwitchFlagEnabled) {
    return true;
  }
  if (window.navigator.userAgent.includes("OneTrust")) {
    return true;
  }
  return false;
}
