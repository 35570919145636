import {
  limitedAccountCheckoutEmail,
  limitedAccountCheckoutEmailChallenged,
  limitedAccountCheckoutEmailError,
  limitedAccountCheckoutEmailLoading,
  makeCheckoutWithSubscription,
} from "./ce-standard-checkout-actions";
import { fetchAuthState } from "./next-user-actions";
import { errorCodes } from "helpers/error-message-maps/auth";
import { StatusCodes } from "http-status-codes";
import { setPostAuthenticationClientSideAction } from "./post-authentication-actions";
import { getUserId } from "../components/source/hoc/with-user-data";
import { signIn } from "helpers/session-helpers";
import $ from "clients/RawClient";

const actions = {
  makeCheckoutPostAuthentication(checkoutId) {
    return (dispatch, getState) => {
      const { userData } = getState();
      const userId = getUserId(userData);
      dispatch(makeCheckoutWithSubscription(userId, checkoutId)).then(() => {
        // set the URL to the checkout ID, now that we have a checkout
        history.replaceState({ id: checkoutId }, "", `/ce-checkout/${checkoutId}`);
      });
    };
  },

  makeLimitedAccount(email) {
    return (dispatch, getState) => {
      dispatch(limitedAccountCheckoutEmailError(false));
      dispatch(limitedAccountCheckoutEmailLoading(true));

      return $.post("/api/auth/rtr-dac/initiation", { email }).then(
        res => {
          dispatch(limitedAccountCheckoutEmailLoading(false));
          dispatch(limitedAccountCheckoutEmail(res.email));
          dispatch(fetchAuthState());
          return dispatch(makeCheckoutWithSubscription(res.user.id)).then(checkoutId => {
            // set the URL to the checkout ID, now that we have a checkout
            history.replaceState({ id: checkoutId }, "", `/ce-checkout/${checkoutId}`);
          });
        },
        e => {
          dispatch(limitedAccountCheckoutEmailLoading(false));

          if (e.status === StatusCodes.CONFLICT) {
            // user will login via PKCE flow and we will create checkout when they come back
            const checkoutId = getState()?.ceCheckout?.id;
            setPostAuthenticationClientSideAction(actions.makeCheckoutPostAuthentication.name, 5, { checkoutId });
            signIn({ loginHint: email, mode: "login" });
            return;
          } else if (
            e.status === StatusCodes.UNAUTHORIZED &&
            e.responseJSON?.error?.toLowerCase() === errorCodes.CHALLENGED
          ) {
            dispatch(limitedAccountCheckoutEmail(email));
            dispatch(limitedAccountCheckoutEmailChallenged(true));
            return;
          }

          dispatch(limitedAccountCheckoutEmailError(e.responseJSON.error));
        }
      );
    };
  },

  /**
   * Respond to a Castle challenge via CAS
   * @param {string} email Email of the account being challenged
   * @param {*} _checkoutId Unused - included for signature parity with legacy respondToChallenge fn
   * @param {string} challengeResponse User's challenge response
   * @returns Promise
   */
  respondToChallenge: (email, _checkoutId, challengeResponse) => {
    //  /rtr-dac/verify-challenge endpoint to complete the challenge and upon success → set tokens as cookies
    return $.post("/api/auth/rtr-dac/verifyChallenge", {
      email,
      challengeResponse,
    }).then(
      res => {
        return Promise.resolve(res);
      },
      e => {
        return Promise.reject(e);
      }
    );
  },

  /**
   * Resend a Castle challenge email via CAS
   * @param {string} email
   * @returns Promise
   */
  resendChallenge: email => {
    return $.post("/api/auth/rtr-dac/resendChallenge", { email }).then(
      res => {
        return Promise.resolve(res);
      },
      e => {
        return Promise.reject(e);
      }
    );
  },
};

export default actions;
export const { makeCheckoutPostAuthentication, makeLimitedAccount, resendChallenge, respondToChallenge } = actions;
