// todo: add other modal names here
export const MODAL_NAMES = {
  PLANS_DRAWER: "PLANS_DRAWER_MODAL",
  REVIEW_CROP_PHOTOS: "REVIEW_CROP_PHOTOS_MODAL",
  REVIEW_UPLOAD: "REVIEW_UPLOAD_MODAL",
  REVIEW_SUBMIT_SUCCESS: "REVIEW_SUBMIT_SUCCESS_MODAL",
  REVIEW_NUDGE: "REVIEW_NUDGE_MODAL",
  NOTIFY_ME: "NOTIFY_ME_MODAL",
  NOTIFY_ME_GTM: "NOTIFY_ME_GTM_MODAL",
  CUSTOMER_PROMISE_MODAL: "CUSTOMER_PROMISE_MODAL",
};
