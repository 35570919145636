import { addMinutes, getTime, isAfter } from "date-fns";
import { addPostAuthentication } from "actions/ce-bag-actions";
import { favoriteProduct, removeProductRating } from "actions/product-ratings-actions";
import { makeCheckoutPostAuthentication } from "actions/delayed-account-creation-actions";
import { LocalStorage } from "../site/localStorage";

const POST_AUTHENTICATION_LOCAL_STORAGE_NAMESPACE = "casPostAuthenticationActions";
const postAuthenticationLocalStorageClient = new LocalStorage(POST_AUTHENTICATION_LOCAL_STORAGE_NAMESPACE);

function getSupportedPostAuthenticationClientSideActions() {
  // NW [EXPLANATION] this is here instead of a const at the top of the file so that we can effectively spy on the invoked action in unit tests
  return {
    [addPostAuthentication.name]: addPostAuthentication,
    [favoriteProduct.name]: favoriteProduct,
    [removeProductRating.name]: removeProductRating,
    [makeCheckoutPostAuthentication.name]: makeCheckoutPostAuthentication,
  };
}

const actions = {
  /**
   * Set client-side behavior to be executed after the user navigates back to Storefront after a successful sign-in.
   * For example, an anonymous user added an item to bag and will be prompted to log in via CAS.
   * We will save the add-to-bag action so it can be completed after successful sign-in.
   * @param {string} actionName Name of the action to be executed
   * @param {number} expirationInMinutes Minutes from now when the action will be thrown out if no successful signin is completed
   * @param {object} payload Payload of the action
   */
  setPostAuthenticationClientSideAction: (actionName, expirationInMinutes, payload) => {
    const expirationMsTimestamp = getTime(addMinutes(new Date(), expirationInMinutes));
    postAuthenticationLocalStorageClient.set(actionName, {
      expiration: expirationMsTimestamp,
      payload,
    });
  },

  /**
   * Execute client-side behavior that was saved before a successful sign-in.
   * For example, an anonymous user added an item to bag and successfully signed in or registered via CAS.
   * We will execute the add-to-bag action as the user intended, within a predetermined timeframe.
   * Expired actions will be ignored and removed from storage.
   * @param {function} dispatch Dispatch function for Redux action
   */
  executePostAuthenticationClientSideActions: () => {
    return dispatch => {
      const keysToRemove = [];

      postAuthenticationLocalStorageClient.iterate((key, value) => {
        const expirationMsTimestamp = value?.expiration || 0;
        const expirationDate = new Date(expirationMsTimestamp);
        if (isAfter(expirationDate, new Date())) {
          const payload = value?.payload;
          const action = getSupportedPostAuthenticationClientSideActions()[key];
          if (action) {
            /* IMPORTANT - the action being dispatched must NOT append to local storage.
             * if it does append to local storage, the action might get invoked twice */
            dispatch(action(...Object.values(payload)));
          }
        }
        keysToRemove.push(key);
      });

      keysToRemove.forEach(key => postAuthenticationLocalStorageClient.remove(key));
    };
  },
};

export default actions;

export const { executePostAuthenticationClientSideActions, setPostAuthenticationClientSideAction } = actions;
