import { priceStringIntoFloat } from "helpers/invoice-helper";

const getPauseWithItemsTier = membershipTiers => {
  if (!membershipTiers || !Array.isArray(membershipTiers)) {
    return null;
  }

  // NW [EXPLANATION] 4/7/20: presently there is only 1 pause with items tier
  // that may change in the future (to support separate inventory eligibilities, for example)
  return membershipTiers.find(tier => {
    return isPauseWithItemsTier(tier);
  });
};

const getPauseWithItemsTierSpotPrice = (membershipTiers, membershipState = {}) => {
  let slotPrice;

  // for future PWI, show them their upcoming price in case PWI price has been changed in between their decision to pause and their bill date
  if (membershipState?.futureMembershipTierRevision?.baseSlotUpgradePrice) {
    slotPrice = membershipState.futureMembershipTierRevision.baseSlotUpgradePrice;
  } else {
    slotPrice = getPauseWithItemsTier(membershipTiers)?.baseSlotUpgradePrice;
  }

  const parsedPrice = priceStringIntoFloat(slotPrice);
  if (!Number.isNaN(parsedPrice)) {
    return parsedPrice;
  }
};

const isPauseWithItemsTier = membershipTier => {
  if (!membershipTier) {
    return false;
  }
  return membershipTier.slotCount === 0 && membershipTier.monthlyShipmentLimit === 0;
};

/**
 * Is it currently possible to sign up for a given tier ID.
 * @param {number} membershipTierId
 * @param {MembershipTier[]} membershipTiers
 */
const isValidActiveTier = (membershipTierId, membershipTiers) => {
  //Tier IDs are strings on MembershipTier because Godmother
  const activeTierIds = membershipTiers.filter(t => !isPauseWithItemsTier(t)).map(t => parseInt(t.id));

  return activeTierIds.includes(membershipTierId);
};

const userCanRejoinWithPriorTier = (membershipState, membershipTiers) => {
  const priorTierId = membershipState.membershipTierId;

  return isValidActiveTier(priorTierId, membershipTiers);
};

export { getPauseWithItemsTier, getPauseWithItemsTierSpotPrice, isPauseWithItemsTier, userCanRejoinWithPriorTier };
