import _ from "underscore";
import PropTypes from "prop-types";
import SizeHelper from "helpers/form-field-size-helper";
import FitRecHelpers from "helpers/fit-rec-helpers";
import MembershipHelpers, { isActiveMembership } from "helpers/membership-helpers";
import { isClearance, isBulk } from "helpers/price-helper";
const AVAILABILITY_PROP_TYPE = PropTypes.arrayOf(
  PropTypes.shape({
    // id is typically here, but not required by this code
    count: PropTypes.number,
  })
);

const exported = {
  LOW_THRESHOLD: 3,

  skuPropTypes: PropTypes.shape({
    // id, size are typically here, but not required by this code
    bulkAvailabilities: AVAILABILITY_PROP_TYPE,
    clearanceAvailabilities: AVAILABILITY_PROP_TYPE,
    rentalAvailabilities: AVAILABILITY_PROP_TYPE,
  }),

  // Check if any availability type has a count value present. These values
  // are only populated if availability data has been fetched.
  availabilityDataFetched: function (product) {
    return (
      product?.skus
        ?.flatMap(sku =>
          Object.keys(sku)
            .filter(k => k.includes("Availabilities"))
            .flatMap(key => sku[key])
        )
        .filter(s => "count" in s).length > 0
    );
  },

  isAvailable: function (skus, availabilityType = "rentalAvailabilities") {
    const counts = _.chain(skus || [])
      .pluck(availabilityType)
      .flatten()
      .pluck("count")
      .value();

    if (
      _.all(_.uniq(counts), function (count) {
        return _.isUndefined(count);
      })
    ) {
      // We have *no* counts, so we didn't make an availability check
      // whatsoever, so let's consider this product available.
      return true;
    }

    return _.any(counts, function (availabilityCount) {
      if (availabilityCount) {
        return availabilityCount > 0;
      } else {
        return false;
      }
    });
  },

  // in case the availability type is not specified we will calculate using rental availability
  availableSkus: function (skus, availabilityType = "rentalAvailabilities") {
    // this is analogous to "has filtered" as it is checking if counts are present
    return _.filter(skus || [], function (sku) {
      return _.any(sku[availabilityType] || [], function (avail) {
        return _.isUndefined(avail.count) || avail.count;
      });
    });
  },

  // This method is similar to "availableSkus" above but for some reason that one considers a sku with an undefined count as a non zero count
  skusWithNonZeroCounts(skus = [], availabilityType = "rentalAvailabilities") {
    return skus.filter(sku => {
      return (sku[availabilityType] || []).some(avail => {
        return avail.count;
      });
    });
  },

  // in case the availability type is not specified we will calculate using rental availability
  filteredSkus: function (skus, filteredSizes, availabilityType = "rentalAvailabilities") {
    const availableSkus = this.availableSkus(skus, availabilityType);

    return _.filter(availableSkus, function (sku) {
      return !_.isEmpty(_.intersection(sku.canonicalSizes, filteredSizes));
    });
  },

  // in case the availability type is not specified we will calculate using rental availability
  lowAvailabilitySku: function (skus, filteredSizes, availabilityType = "rentalAvailabilities") {
    const filteredSkus = this.filteredSkus(skus, filteredSizes, availabilityType);

    return _.find(
      filteredSkus,
      function (sku) {
        const { count } = _.first(sku[availabilityType]);
        return count > 0 && count <= this.LOW_THRESHOLD;
      },
      this
    );
  },

  // in case the availability type is not specified we will calculate using rental availability
  availabilityType: function (merchandiseCategory = "RENTAL") {
    switch (merchandiseCategory) {
      case "BULK":
        return "bulkAvailabilities";
      case "CLEARANCE":
        return "clearanceAvailabilities";
      case "RENTAL":
      /* falls through */
      default:
        return "rentalAvailabilities";
    }
  },

  // This is an updated version of the above, that one does not consider membership
  getAvailabilityType: function (userData, product, isBuyNow) {
    let availabilityType;

    if (isClearance(product?.price)) {
      availabilityType = "clearanceAvailabilities";
    } else if (isBulk(product?.price)) {
      availabilityType = "bulkAvailabilities";
    } else if (MembershipHelpers.isUnlimitedLens(userData) || isBuyNow) {
      availabilityType = "unlimitedAvailabilities";
    } else if (MembershipHelpers.isRtrUpdateLens(userData)) {
      availabilityType = "rtrUpdateAvailabilities";
    } else {
      availabilityType = "rentalAvailabilities";
    }

    return availabilityType;
  },

  lowAvailabilityCount: function (skus, filteredSizes, merchandiseCategory = "RENTAL") {
    const availabilityType = this.availabilityType(merchandiseCategory);

    const sku = this.lowAvailabilitySku(skus, filteredSizes, availabilityType);

    if (!sku) return;

    const availabilities = sku[availabilityType];

    if (!availabilities || !availabilities.length) return;

    return availabilities[0].count;
  },

  lowCountFromAvailabilityObject: function (sku, availability) {
    if (!sku) return;

    const counts = availability?.counts || {};
    const count = counts[sku];

    if (count > this.LOW_THRESHOLD) return;

    return count;
  },

  sizeToAvailabilityCountMap: function (product = {}, membershipState = {}) {
    // returns an object: { S: { count: 3 }, M: {count: 4}, L: {}... }
    return product?.skus?.reduce((memo, sku) => {
      //Prospects are treated as eligible for unlimited (Full Closet) access
      const isProspect = !isActiveMembership(membershipState);
      const membershipAvailability = isProspect
        ? sku.unlimitedAvailabilities?.[0]
        : MembershipHelpers.getAvailabilityFromMembershipState(membershipState, sku);
      memo[sku.size] = _.pick(membershipAvailability, ["count"]);
      return memo;
    }, {});
  },

  hasSkuCount: function (selectedSize, product = {}, membershipState = {}) {
    const allSkusCounts = this.sizeToAvailabilityCountMap(product, membershipState);

    return Boolean(allSkusCounts?.[selectedSize]?.count);
  },

  allSizesUnavailable: function (product = {}, membershipState = {}) {
    const allSkusCounts = this.sizeToAvailabilityCountMap(product, membershipState);
    return !Object.values(allSkusCounts).some(sku => sku.count);
  },

  sizeWithDefaultLength: function (listOfSizes, product = {}, membershipState = {}) {
    // Due to some "interesting" size scales e.g.
    // ["00P", "00R", "00L", "00XL", "0P", "0R", "0L", "0XL"], ["XXSP", "XXSR", "XXSL", "XXSXL", "XSP", "XSR", "XSL", "XSXL"]
    // We can match for the defaultLength "R" but also check for the count to preselect the correct one.
    return listOfSizes?.find(
      size => size.match(SizeHelper.constants.defaultLength) && this.hasSkuCount(size, product, membershipState)
    );
  },

  regionalSizeFilters: function (workingFilters, product = {}, membershipState = {}) {
    return workingFilters?.canonicalSizes.map(size => {
      if (SizeHelper.hasLengths(product)) {
        return this.sizeWithDefaultLength(SizeHelper.regionalSizeList(product, size), product, membershipState);
      } else {
        return SizeHelper.regionalSizeList(product, size);
      }
    });
  },

  filteredSizeWithACount: function (workingFilters, product = {}, membershipState = {}) {
    let filteredSizes = [];

    if (!SizeHelper.isUSAStandardSizes(product)) {
      filteredSizes = this.regionalSizeFilters(workingFilters, product, membershipState);
    } else {
      filteredSizes = workingFilters.canonicalSizes;
    }

    return filteredSizes
      .filter(Boolean)
      .reduce((a, b) => a.concat(b), [])
      .find(size => this.hasSkuCount(size, product, membershipState));
  },

  /**
   * Getter functions that looks at a product's skus, working filters attached and primary size
   * and returns the SKU that best fits a user
   * @argument product product we are checking the recommended skus for
   * @argument userData user to check for primarySize
   * @argument membershipState used for checking membership availability to an item
   * @argument workingFilters additional canonical sizes if primarySize isn't accessible
   * @argument returnUnavailableSizes bool that controls whether we get a user's recommended sku that's available or out of stock

   *
   * @returns selected sku that a user defaults to, even if sku is unavailable
   */
  getDefaultSizeSelection: function (
    product = {},
    userData = {},
    membershipState = {},
    workingFilters = {},
    returnUnavailableSizes = false
  ) {
    let { defaultSizeSelection } = SizeHelper.constants;
    const { fitRates } = product;
    const primarySize = userData.userProfile?.profiles?.primarySize;

    // If sizeless accessory, default to empty value input === "", save sku for adding and return out.
    if (SizeHelper.isOneSizeFitsAll(product)) {
      // props.product.skus always returns a list. In the one size fits all case, it's a list of 1.
      return {
        size: defaultSizeSelection,
        sku: product.skus?.[0]?.id,
      };
    }

    // If a fit rate of over 75% exists for product then user is auto selected into that size - AS 4/27/2022
    if (fitRates?.length > 0) {
      const recommendedSizes = FitRecHelpers.allRecommended(fitRates, primarySize);
      if (recommendedSizes?.length > 0) {
        if (returnUnavailableSizes) {
          return {
            size: recommendedSizes[0].size,
            sku: product.skus.find(sku => sku.size === recommendedSizes[0].size)?.id,
          };
        } else {
          const recommendedAvailableSizes = recommendedSizes.find(fitRate =>
            this.hasSkuCount(fitRate.size, product, membershipState)
          );

          if (recommendedAvailableSizes) {
            return {
              size: recommendedAvailableSizes.size,
              sku: product.skus.find(sku => sku.size === recommendedAvailableSizes.size)?.id,
            };
          }
        }
      }
    }

    // if there arent any good recommendations from the fit rate
    // and we want a sku no matter if its available or out of stock
    // return the sku with the most matching canonical sizes
    if (returnUnavailableSizes) {
      const selectedSku = product.skus
        .reduce(
          (acc, curr) => [
            ...acc,
            {
              id: curr.id,
              count: workingFilters.canonicalSizes.filter(size => curr.canonicalSizes?.includes(size))?.length || 0,
              size: curr.size,
            },
          ],
          []
        )
        .sort((a, b) => b.count - a.count)[0];
      return {
        size: selectedSku.size,
        sku: selectedSku.id,
      };
    }

    if (SizeHelper.hasWorkingSizeFilters(workingFilters)) {
      defaultSizeSelection = this.filteredSizeWithACount(workingFilters, product, membershipState);
    }

    return {
      size: defaultSizeSelection,
      sku: product.skus?.find(sku => sku.size === defaultSizeSelection)?.id,
    };
  },

  checkProductIsEligibleForUser: function (product = {}, membershipState = {}) {
    return (
      product.eligibleFor &&
      product.eligibleFor.some(tier => {
        return MembershipHelpers.hasInventoryEligibility(membershipState, tier);
      })
    );
  },

  isUnavailableToRent: function (product = {}, userData = {}, membershipState = {}, workingFilters) {
    const defaultSize = this.getDefaultSizeSelection(product, userData, membershipState, workingFilters)?.size;

    return (
      (!this.hasSkuCount(defaultSize, product, membershipState) && SizeHelper.hasProductSizes(product)) ||
      (SizeHelper.isOneSizeFitsAll(product) && product.skus?.[0].unlimitedAvailabilities?.[0].count === 0) ||
      !this.checkProductIsEligibleForUser(product, membershipState)
    );
  },

  isOneSizeProductOutOfStock(product) {
    return product.skus[0].unlimitedAvailabilities[0].count === 0;
  },
};

export default exported;

export const {
  allSizesUnavailable,
  availabilityType,
  availableSkus,
  buynowgrid,
  checkProductIsEligibleForUser,
  filteredSizeWithACount,
  filteredSkus,
  getAvailabilityType,
  getDefaultSizeSelection,
  hasSkuCount,
  isAvailable,
  isOneSizeProductOutOfStock,
  isUnavailableToRent,
  LOW_THRESHOLD,
  lowAvailabilityCount,
  lowAvailabilitySku,
  lowCountFromAvailabilityObject,
  regionalSizeFilters,
  sizeToAvailabilityCountMap,
  sizeWithDefaultLength,
  skuPropTypes,
  skusWithNonZeroCounts,
} = exported;
