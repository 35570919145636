const NavLinksResponse = [
  {
    text: "Browse all",
    href: "/products?sort=newest",
  },
  {
    text: "New",
    href: "/shop/new_arrivals_app/products",
  },
  {
    text: "Workwear",
    href: "/shop/work_clothing/products",
  },
  {
    text: "Everyday",
    href: "/shop/everyday_clothing/products",
  },
  {
    text: "Events",
    href: "/shop/parties/products?lens=classic",
  },
  {
    text: "Vacations",
    href: "/shop/vacation_clothing/products?lens=classic",
  },
  {
    text: "Weddings",
    href: "/c/wedding-guest-dresses?lens=classic",
  },
  {
    text: "Designers",
    href: "/designers",
  },
  {
    text: "Trends",
    href: "#",
  },
  {
    text: "Maternity",
    href: "#",
  },
];

export default NavLinksResponse;
