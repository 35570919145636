import PropTypes from "prop-types";

const NavItems = props => {
  const { items, parentClass, childClass } = props;

  return (
    <nav>
      <ul className={parentClass} data-test-id="nav-items">
        {items.length > 0 &&
          items.map(item => (
            <li className={childClass} key={`nav-item-${item.text.replace(" ", "_")}`}>
              <a href={item.href} className="duration-200 hover:text-darkGray">
                {item.text}
              </a>
            </li>
          ))}
      </ul>
    </nav>
  );
};

NavItems.propTypes = {
  items: PropTypes.array,
  parentClass: PropTypes.string,
  childClass: PropTypes.string,
};

export default NavItems;
