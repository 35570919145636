import React from "react";
import { footerPropType } from "../../propTypes";
import MoleculeEmailCapture from "components/source/molecules/molecule-email-capture";
import CuratorIcon from "../../../../../public/images/curator_icon.svg";
import AppIcon from "../../../../../public/images/sprites/reb/svg/app_icon.svg";
import TikTokIcon from "../../../../../public/images/sprites/reb/svg/tiktok-icon.svg";
import InstagramIcon from "../../../../../public/images/sprites/reb/svg/instagram_icon.svg";
import FacebookIcon from "../../../../../public/images/sprites/reb/svg/facebook_icon.svg";
import PinterestIcon from "../../../../../public/images/sprites/reb/svg/pinterest_icon.svg";
import TwitterIcon from "../../../../../public/images/sprites/reb/svg/twitter_icon.svg";

function Footer({ footer = {} }) {
  const renderLinkSection = (section, i) => {
    return (
      <div data-test-id={`link-section-${i}`} key={section.sectionHeaderCopy}>
        <p className="font-eyebrow mb-1">{section.sectionHeaderCopy}</p>
        <ul>
          {section.links.map((link, i) => {
            return (
              <li className="py-1" key={i}>
                <a href={link.href}>{link.copy}</a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const renderLegalLinks = () => {
    // Classname "ot-sdk-show-settings" is used by OneTrust cookie compliance tool
    return (
      <div>
        {footer.legalLinkSection.map((link, i) => {
          return (
            <a className="px-2" href={link.href} key={i}>
              {link.copy}
            </a>
          );
        })}
        <button
          id="ot-sdk-btn"
          data-test-id="onetrust-footer-link"
          className="ot-sdk-show-settings !text-md !text-inherit !border-0 !bg-transparent !p-0 !px-2 !m-0">
          Your Privacy Choices
        </button>
      </div>
    );
  };

  const renderAppDownloadPrompt = () => {
    const { appDownloadPrompt } = footer;

    if (!appDownloadPrompt) {
      return;
    }

    return (
      <div className="flex">
        <AppIcon />
        <div className="flex flex-col">
          <span className="font-eyebrow my-2">{appDownloadPrompt.annotationCopy}</span>
          <a href="https://rtr.app.link/dsabSPp3GB" className="font-eyebrow underline text-md">
            {appDownloadPrompt.ctaCopy}
          </a>
        </div>
      </div>
    );
  };

  const renderSocialIcons = () => {
    const { socialLinkSection, socialLinkSection: { activeSocialMediaLinks } = {} } = footer;

    return (
      <div className="social-footer-icons">
        <div className="font-eyebrow">{socialLinkSection.sectionHeaderCopy}</div>
        <div className="flex">
          {activeSocialMediaLinks.includes("INSTAGRAM") && (
            <a href="http://www.instagram.com/renttherunway" aria-label="instagram">
              <InstagramIcon />
            </a>
          )}
          {activeSocialMediaLinks.includes("TIKTOK") && (
            <a href="https://www.tiktok.com/@renttherunway" aria-label="tiktok">
              <TikTokIcon />
            </a>
          )}
          {activeSocialMediaLinks.includes("FACEBOOK") && (
            <a href="https://www.facebook.com/RentTheRunway" aria-label="facebook">
              <FacebookIcon />
            </a>
          )}
          {activeSocialMediaLinks.includes("PINTEREST") && (
            <a href="http://www.pinterest.com/renttherunway/" aria-label="pinterest">
              <PinterestIcon />
            </a>
          )}
          {activeSocialMediaLinks.includes("TWITTER") && (
            <a href="https://twitter.com/RenttheRunway" aria-label="twitter">
              <TwitterIcon />
            </a>
          )}
        </div>
      </div>
    );
  };

  const renderCuratorsLink = () => {
    const { curatorsLink } = footer;

    if (!curatorsLink) {
      return null;
    }

    return (
      <div className="flex">
        <CuratorIcon />
        <div className="flex flex-col ml-2 mt-2">
          <span className="mb-1">{curatorsLink.annotationCopy}</span>
          <a href="https://www.renttherunway.com/content/rtrcurators" className="font-eyebrow underline">
            {curatorsLink.ctaCopy}
          </a>
        </div>
      </div>
    );
  };

  const renderSocialSection = () => {
    return (
      <div className="grid grid-cols-2 md:grid-cols-4 gap-8 md:gap-16 pb-6">
        {renderAppDownloadPrompt()}
        {renderCuratorsLink()}
        {renderSocialIcons()}
      </div>
    );
  };

  const renderFooterBottom = () => {
    return (
      <div className="flex flex-col align-center text-center mt-8">
        <div>©{new Date().getFullYear()} Rent the Runway. All Rights Reserved.</div>
        {renderLegalLinks()}
      </div>
    );
  };

  const render = () => {
    return (
      <>
        <MoleculeEmailCapture copy={footer.emailCaptureForm} />
        <div id="footer" className="flex justify-center bg-offWhite border-t border-t-lighterGray">
          <div className="py-12 flex flex-col p-4">
            <div className={`grid grid-cols-2 md:grid-cols-4 gap-8 md:gap-16 pb-12`}>
              <>{footer.linkSections.map((ls, i) => renderLinkSection(ls, i))}</>
            </div>
            {renderSocialSection()}
            {renderFooterBottom()}
          </div>
        </div>
      </>
    );
  };

  return render();
}

Footer.propTypes = footerPropType;

export default Footer;
