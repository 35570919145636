import { GA4_EVENTS, membershipStyles } from "rtr-constants";
import { hexSha256Hash } from "helpers/hashing-helper";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const currencyRegex = /[^0-9.]/g;
const affiliated = "Rent The Runway";
const localCurrency = "USD";
const getAttentiveEmail = () => cookies.get("attntv_mstore_email");

const rtr_id = () => cookies.get("RTR_ID");
const rtr_sess = () => cookies.get("RTR_SESS");

const stripEmptyKeys = object => {
  if (!object || typeof object !== "object") {
    return {};
  }
  return Object.fromEntries(Object.entries(object).filter(([_key, value]) => value !== ""));
};

const hashEmail = async email => hexSha256Hash(email?.trim()?.toLowerCase());

const getEmail = async email => {
  const hashedEmail = await hashEmail(email);
  const attentiveEmail = getAttentiveEmail();
  const email_sha256 = hashedEmail ? hashedEmail : await hashEmail(attentiveEmail);
  return email_sha256;
};

function pushToDataLayer(event) {
  function callback() {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer?.push(event);
    window.removeEventListener("googleTagManagerLoadEvent", callback);
  }

  if (window.google_tag_manager?.dataLayer?.gtmLoad) {
    callback();
  } else {
    window.addEventListener("googleTagManagerLoadEvent", callback);
  }
}

async function removeFromCartEvent(product, skuId, userId, userEmail) {
  let analyticsItem;
  try {
    const email_sha256 = await getEmail(userEmail);

    if (skuId === membershipStyles.UPDATE) {
      analyticsItem = membershipRemoveFromCart(product);
    } else {
      analyticsItem = {
        item_id: skuId?.id || skuId,
        item_name: product?.displayName || "",
        affiliation: affiliated,
        currency: localCurrency,
        item_brand: product?.displayName || "",
        item_category: product?.category?.id || "",
        price: product?.price?.replace(currencyRegex, "") || "",
      };
    }

    // strip away any empty fields that are not available to send in Event
    const clearEmptyField = stripEmptyKeys(analyticsItem);

    const event_data = {
      currency: localCurrency,
      value: product?.price?.replace(currencyRegex, "") || "",
      items: clearEmptyField,
      userId: userId,
      hashEmail: email_sha256,
      browserId: rtr_id(),
      sessionId: rtr_sess(),
    };

    pushToDataLayer({
      event: GA4_EVENTS.GA4_REMOVE_FROM_CART,
      ecommerce: event_data,
    });
  } catch (e) {
    window?.Sentry?.captureException(e);
  }
}

async function addToCartEvent(product, skuId, isMembership, userId, userEmail) {
  let analyticsItem;
  try {
    const designer = product?.designer?.displayName;
    const size = skuId?.split("_")[1];
    const email_sha256 = await getEmail(userEmail);

    if (isMembership) {
      analyticsItem = membershipAddToCart(product);
    } else {
      analyticsItem = {
        item_id: skuId.id || skuId,
        item_name: designer || "",
        item_variant_id: size || "",
        image_url: product?.images?.noModel?.["1080x"] || "",
        affiliation: affiliated,
        currency: localCurrency,
        item_brand: designer || "",
        item_category: product?.category?.id || "",
        price: product?.purchasePrice?.replace(currencyRegex, "") || "",
      };
    }

    // strip away any empty fields that are not available to send in Event
    const clearEmptyField = stripEmptyKeys(analyticsItem);

    const event_data = {
      currency: localCurrency,
      value: analyticsItem.price,
      items: clearEmptyField,
      userId: userId,
      hashEmail: email_sha256,
      browserId: rtr_id(),
      sessionId: rtr_sess(),
    };

    pushToDataLayer({
      event: GA4_EVENTS.GA4_ADD_TO_CART,
      ecommerce: event_data,
    });
  } catch (e) {
    window?.Sentry?.captureException(e);
  }
}

function membershipAddToCart(membershipTier) {
  const membershipSku = membershipStyles.UPDATE;
  try {
    return {
      item_id: membershipSku,
      item_name: membershipTier?.name,
      affiliation: affiliated,
      currency: localCurrency,
      item_category: "Membership",
      item_variant_id: membershipTier?.name
        ? membershipStyles.UPDATE + "_" + membershipTier?.name
        : membershipStyles.UPDATE,
      price: membershipTier?.basePrice.replace(currencyRegex, ""),
    };
  } catch (e) {
    window?.Sentry?.captureException(e);
  }
}

function membershipRemoveFromCart(membershipTier) {
  try {
    let membershipItemDetail = "";
    const membershipSku = membershipStyles.UPDATE;
    if (membershipTier?.skus) {
      membershipItemDetail = membershipTier?.skus[0].id;
    } else {
      membershipItemDetail = membershipStyles.UPDATE + "_" + membershipTier?.name;
    }

    return {
      item_id: membershipSku,
      item_name: membershipTier?.name || membershipTier?.displayName,
      affiliation: affiliated,
      currency: localCurrency,
      item_category: "Membership",
      item_variant_id: membershipItemDetail,
      price: membershipTier?.price || membershipTier?.basePrice.replace(currencyRegex, ""),
      orderId: membershipTier?.orderId,
    };
  } catch (e) {
    window?.Sentry?.captureException(e);
  }
}

async function viewItemEvent(item, userId, userEmail) {
  try {
    const price = item?.product?.purchasePrice || item.purchasePrice;
    const priceStripNonNumerChars = price?.replace(currencyRegex, "");
    const email_sha256 = await getEmail(userEmail);

    const analyticsItem = {
      item_id: item.sku || item.price?.id,
      item_name: item.product?.displayName || item.displayName,
      affiliation: affiliated,
      currency: localCurrency,
      item_brand: item.product?.designer?.displayName || item.designer?.id,
      item_category: item?.category?.id,
      price: priceStripNonNumerChars,
    };

    const event_data = {
      currency: localCurrency,
      value: priceStripNonNumerChars,
      items: analyticsItem,
      userId: userId,
      hashEmail: email_sha256,
      browserId: rtr_id(),
      sessionId: rtr_sess(),
    };

    pushToDataLayer({
      event: GA4_EVENTS.GA4_ITEM_SELECT,
      ecommerce: event_data,
    });
  } catch (e) {
    window?.Sentry?.captureException(e);
  }
}

async function viewPlansEvent(item, userId, userEmail) {
  try {
    const price = item?.product?.purchasePrice || item.purchasePrice;
    const priceStripNonNumerChars = price?.replace(currencyRegex, "");
    const email_sha256 = await getEmail(userEmail);

    const analyticsItem = {
      item_id: item.sku || item.price?.id,
      item_name: item.product?.displayName || item.displayName,
      affiliation: affiliated,
      currency: localCurrency,
      item_brand: item.product?.designer?.displayName || item.designer?.id,
      item_category: item?.category?.id,
      price: priceStripNonNumerChars,
    };

    const event_data = {
      currency: localCurrency,
      value: priceStripNonNumerChars,
      items: analyticsItem,
      userId: userId,
      hashEmail: email_sha256,
      browserId: rtr_id(),
      sessionId: rtr_sess(),
    };

    pushToDataLayer({
      event: GA4_EVENTS.GA4_VIEW_PLANS,
      ecommerce: event_data,
    });
  } catch (e) {
    window?.Sentry?.captureException(e);
  }
}

const beginStandardCheckoutEvent = async (checkout, userProfile) => {
  try {
    pushToDataLayer({
      event: GA4_EVENTS.GA4_BEGIN_CHECKOUT,
      ecommerce: await checkoutDataBuilder(checkout, null, userProfile),
    });
  } catch (e) {
    window?.Sentry?.captureException(e);
  }
};

const purchaseEventFromCheckout = async (checkout, orderId = null, userProfile) => {
  try {
    pushToDataLayer({
      event: GA4_EVENTS.GA4_PURCHASE,
      ecommerce: await checkoutDataBuilder(checkout, orderId, userProfile),
    });
  } catch (e) {
    window?.Sentry?.captureException(e);
  }
};

const promotionEventFromCheckout = async (checkout, promoApplied, userProfile) => {
  try {
    pushToDataLayer({
      event: GA4_EVENTS.GA4_PROMOTION,
      ecommerce: {
        creative_name: promoApplied,
        items: await checkoutDataBuilder(checkout, null, userProfile),
        userId: userProfile?.id,
      },
    });
  } catch (e) {
    window?.Sentry?.captureException(e);
  }
};

async function pushEnhancedConversionDataToDataLayer(address, email, userId) {
  const normalizeString = str => str?.trim?.()?.toLowerCase?.();
  const hashNormalizedString = str => hexSha256Hash(normalizeString(str));

  const hashedEmail = await hashNormalizedString(email);
  const attentiveEmail = getAttentiveEmail();
  const email_sha256 = hashedEmail ? hashedEmail : await hashEmail(attentiveEmail);

  // NW [EXPLANATION] 10/10/23: a country code is required for enhanced conversion but we do not collect country codes. see MA-2716
  const sanitizedPhone = address?.phone?.replace?.(/[^0-9]/g, "")?.padStart(12, "+1");

  try {
    pushToDataLayer({
      enhanced_conversion_data: {
        address: {
          sha256_first_name: await hashNormalizedString(address?.firstName),
          sha256_last_name: await hashNormalizedString(address?.lastName),
          street: normalizeString(address?.street1),
          city: normalizeString(address?.city),
          region: normalizeString(address?.zoneCode || address?.state),
          postal_code: normalizeString(address?.postalCode),
          country: "US", // NW [EXPLANATION] 9/20/23: only US addresses are currently supported; ISO 3166-1 alpha-2 required
        },
        sha256_email_address: email_sha256,
        sha256_phone_number: await hashNormalizedString(sanitizedPhone),
        userId: userId,
        browserId: rtr_id(),
        sessionId: rtr_sess(),
      },
    });
  } catch (e) {
    window?.Sentry?.captureException?.(e);
  }
}

const checkoutDataBuilder = async (checkout, orderId = null, userProfile = {}) => {
  try {
    const { bagItems } = checkout;
    const email_sha256 = await getEmail(userProfile?.email);
    bagItems.forEach(item => {
      item.totals = checkout?.charges?.itemTotals[item.id];
    });

    const ga4AnalyticsItems = bagItems
      .map(item => ({
        item_id: item.styleName,
        item_name: item.displayName,
        image_url: item?.images || {},
        item_variant_id: item.size || "",
        affiliation: affiliated,
        currency: localCurrency,
        item_brand: item.designerName,
        item_category: item?.category ?? null,
        discount: item.totals.promoItem?.replace(currencyRegex, ""),
        price: item.totals.sub?.replace(currencyRegex, ""),
        orderId: orderId,
        checkout_id: checkout?.id,
      }))
      .map(stripEmptyKeys);

    const metaAnalyticsItems = bagItems.map(item => ({
      id: item.styleName,
      quantity: 1,
      item_price: item.totals.sub?.replace(currencyRegex, ""),
      designer: item.designerName,
      size: item.size,
    }));

    const northbeamLineItems = bagItems.map(item => ({
      productId: item.styleName,
      variantId: item.sku,
      productName: item.displayName,
      variantName: item.size,
      price: item.totals.sub?.replace(currencyRegex, ""),
      quantity: 1,
    }));

    const pepperJamAnalyticsItems = bagItems.map(item => ({
      id: item.styleName,
      price: item.totals.sub?.replace(currencyRegex, ""),
      quantity: 1,
    }));

    const allSizes = Array.from(new Set(...bagItems.map(item => item.size)));
    const isNewCustomer = userProfile?.isCustomer ? 0 : 1;

    const eCommerceData = stripEmptyKeys({
      transaction_id: orderId || checkout.id,
      checkout_id: checkout.id,
      value: checkout.charges.totals?.sub.replace(currencyRegex, ""),
      tax: checkout.charges.totals?.tax.replace(currencyRegex, ""),
      shipping: checkout.charges.totals?.shipping.replace(currencyRegex, ""),
      currency: localCurrency,
      coupon: checkout.promoCode || "",
      quantity: bagItems.length,
      userType: isNewCustomer,
      //Meta fields
      checkoutStyleNames: bagItems.map(item => item.styleName),
      checkoutPrices: bagItems.map(item => item.totals.sub?.replace(currencyRegex, "")),
      sizes: allSizes,
      items: ga4AnalyticsItems,
      facebookCheckoutContent: metaAnalyticsItems,
      northbeamLineItems: northbeamLineItems,
      pepperJamAnalyticsItems: pepperJamAnalyticsItems,
      userId: userProfile?.id,
      hashEmail: email_sha256,
      browserId: rtr_id(),
      sessionId: rtr_sess(),
    });
    return eCommerceData;
  } catch (e) {
    window?.Sentry?.captureException(e);
  }
};

export default {
  addToCartEvent,
  beginStandardCheckoutEvent,
  purchaseEventFromCheckout,
  promotionEventFromCheckout,
  pushEnhancedConversionDataToDataLayer,
  pushToDataLayer,
  removeFromCartEvent,
  viewItemEvent,
  viewPlansEvent,
};
